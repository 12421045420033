import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { RetellWebClient } from 'retell-client-js-sdk';
import RandomWaveViz from './RandomWaveViz';
import StarRating from './StarRating';
import ReactGA from "react-ga4";
import { useNavigate } from 'react-router-dom';
import UserFormTable from './VIPUserForm';
import PreFilledForm from './VIPPreFilledForm';

const DEFAULT_PHONE_NUMBER = '+14152334023';
const DEFAULT_AGENT_ID = '893c8f270b4a5fb7bebc5146910b420e';

const VIPCallCard = ({
                         practiceName,
                         phoneNumber,
                         isExpanded,
                         setIsExpanded,
                         hasTriggeredConfetti,
                         setHasTriggeredConfetti,
                         isCustomPractice,
                         agentID,
                         vipId,
                         vipConfig,
                         label,
                         design,
                         contact
                     }) => {
    const [isMobile, setIsMobile] = useState(false);
    const [retellWebClient, setRetellWebClient] = useState(() => new RetellWebClient());
    const [retellStartCallData, setRetellStartCallData] = useState(null);
    const [callInProgress, setCallInProgress] = useState(false);
    const [isTalking, setIsTalking] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const hasFetchedRef = useRef(false);
    const [rating, setRating] = useState(0);
    const [callEnded, setCallEnded] = useState(false);
    const navigate = useNavigate();
    const [isPopupVisible, setPopupVisible] = useState(false);
    const [submittedData, setSubmittedData] = useState(null);
    const [dataSubmitted, setDataSubmitted] = useState(false);
    const [finalSubmittedData, setFinalSubmittedData] = useState(null);
    const [finalFormSubmitted, setFinalFormSubmitted] = useState(false);
    const [contactId, setContactId] = useState(null);

    const handleFormSubmit = (data) => {
        setSubmittedData(data);
        setDataSubmitted(true);
  
      };
    const handleFinalFormSubmit = (data) => {
        setIsLoading(true);
        setFinalSubmittedData(data);
        setFinalFormSubmitted(true);
    };

    useEffect(() => {
        const postFormData = async () => {
          // Ensure form data is not null or empty before posting
          
          if (dataSubmitted) {
            setIsLoading(true);
            try {
              const response = await fetch('https://api.app.yib.io/api/v1/register-lead', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: submittedData.name,
                    phone: submittedData.countryCode.toString() + submittedData.phoneNumber.toString(),
                    source: "lead form"
                  }),
              });
              const data = await response.json();
              console.log('Response from API:', data);
              setContactId(data.results.contact_id); // Store the contact_id
            } catch (error) {
              console.error('Error posting data:', error);
              
            } finally {
              setIsLoading(false);
            }
          }
        };
        postFormData();
      }, [submittedData]); 
    useEffect(() => {
        if (contactId) {
            handleCallClick();
        }
    }, [contactId]);
    useEffect(() => {
        const postFormData = async () => {
          // Ensure form data is not null or empty before posting
          if (finalFormSubmitted ) {
            setIsLoading(true);
            try {
              const response = await fetch('https://api.app.yib.io/api/v1/register-lead', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: finalSubmittedData.name,
                    phone: finalSubmittedData.phoneNumber.toString(),
                    email: finalSubmittedData.email,
                  }),
              });
              const data = await response.json();
              console.log('Response from API:', data);
            } catch (error) {
              console.error('Error posting data:', error);
              
            } finally {
              setIsLoading(false);
            }
          }
        };
        postFormData();
    }, [finalSubmittedData]); 



    console.log(vipConfig)


    const isKateDesign = design === 'kate';

    const buttonStyle = useMemo(() => {
        if (isKateDesign) {
            return {
                backgroundColor: '#4ABA75',
                color: '#FFFFFF',
                hoverBg: '#3D9D62',
                padding: '0.75rem 1.5rem',
                fontSize: '1.125rem',
                height: 'auto',
                minHeight: '3rem',
                borderRadius: '9999px', // Fully rounded corners
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '0.5rem'
            };
        } else if (design === 'orange') {
            return {
                backgroundColor: '#eb9637',
                color: '#FFFFFF',
                hoverBg: '#d88730',
                padding: '0.5rem 1rem',
                fontSize: '1rem',
                height: 'auto',
                minHeight: '2.5rem',
                borderRadius: '0.375rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '0.5rem'
            };
        }
        return {
            backgroundColor: 'white',
            color: '#8B5CF6',
            hoverBg: '#8B5CF6',
            padding: '0.5rem 1rem',
            fontSize: '1rem',
            height: 'auto',
            minHeight: '2.5rem',
            borderRadius: '0.375rem' // Default rounded corners
        };
    }, [isKateDesign]);

    const buttonText = isKateDesign ? 'Talk to Kate' : 'Talk Now';


    const findValueInJsonString = (jsonString, keyToFind) => {


        try {
            // Escape the key to avoid regex special characters issues
            const escapedKey = keyToFind.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

            // Regular expression to find the key and its value
            // Handles both single and double quotes around the value
            const regex = new RegExp(`['"]${escapedKey}['"]\\s*:\\s*['"](.*?)['"]`, 's'); // Non-greedy match

            // Search for the key in the string
            const match = jsonString.match(regex);

            // Return the value if found
            if (match && match[1]) {
                return match[1];
            } else {
                console.log(`Key "${keyToFind}" not found.`);
                return null;
            }
        } catch (error) {
            console.error('Error searching in JSON string:', error);
            return null;
        }

    };

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/customcall",
            title: "Call Custom AI Dental Assistant"
        });
    }, []);

    const fetchRetellCallData = useCallback(async () => {
        if (hasFetchedRef.current) return retellStartCallData;
        try {
            const apiKey = process.env.REACT_APP_RETELL_API_KEY;
            const url = 'https://api.retellai.com/v2/create-web-call';
            const headers = {
                'Authorization': `Bearer ${apiKey}`,
                'Content-Type': 'application/json'
            };
            const body = {
                agent_id: vipConfig["agent_id"],
                metadata: {}
            };
            
            // Include label in the metadata if it exists
            // if (label) {
            //     body.metadata.label = label;
            // }
            
            // Include contact in the metadata if it exists
            if (contactId) {
                body.metadata.contact = contactId; // Use the stored contactId
            }
            
            if (submittedData && submittedData.name && submittedData.name.trim() !== '') {
                body.metadata.caller_name = submittedData.name;
            }
            
            const response = await fetch(url, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(body)  // Make sure to stringify the body
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setRetellStartCallData(data);
            hasFetchedRef.current = true;
            return data;
        } catch (error) {
            console.error("Error fetching Retell call data:", error);
            return null;
        }
    }, [agentID, label, contactId, retellStartCallData]);

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
        };
        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    const checkAudioPermissions = async () => {
        try {
            await navigator.mediaDevices.getUserMedia({ audio: true });
            return true;
        } catch (err) {
            console.error("Error accessing microphone:", err);
            return true;
        }
    };

    const handleCallClick = async () => {
        
        if (callInProgress || isLoading) return;

        const hasPermission = await checkAudioPermissions();
        if (!hasPermission) {
            const confirmCall = window.confirm("No microphone detected. Would you like to call the phone number instead?");
            if (confirmCall) {
                window.location.href = `tel:${phoneNumber || DEFAULT_PHONE_NUMBER}`;
            }
            return;
        }

        try {
            setIsLoading(true);

            const newRetellWebClient = new RetellWebClient();
            setRetellWebClient(newRetellWebClient);

            const data = await fetchRetellCallData();
            if (!data || !data.access_token) {
                throw new Error("Failed to obtain access token");
            }

            await newRetellWebClient.startCall({
                accessToken: data.access_token,
            });

            setCallInProgress(true);
        } catch (error) {
            console.error("Error starting call:", error);
            alert("There was an error starting the call. Please try again later.");
        } finally {
            setIsLoading(false);
        }
    };

    const stopCall = useCallback(() => {
        try {
            retellWebClient.stopCall();
            setCallInProgress(false);
            setIsTalking(false);
            hasFetchedRef.current = false;
            setCallEnded(true);
            setTimeout(() => {
                setIsLoading(false);
            }, 2000);
        } catch (error) {
            console.error("Error stopping call:", error);
        }
    }, [retellWebClient]);

    const handleRatingSubmit = (rating, feedback) => {
        ReactGA.event({
            category: "User Interaction",
            action: "Rating Submitted",
            label: `Rating: ${rating}, Feedback: ${feedback.slice(0, 50)}...`
        });
        // navigate('/talk-to-yobi-team');
        setPopupVisible(true);

    };
    const closePopup = () => {
        setPopupVisible(false);
    };
    useEffect(() => {
        const setupEventListeners = (client) => {
            client.on("agent_start_talking", () => setIsTalking(true));
            client.on("agent_stop_talking", () => setIsTalking(false));
            client.on("call_started", () => console.log("call started"));
            client.on("call_ended", () => {
                console.log("call ended");
                setCallInProgress(false);
                setIsTalking(false);
                setCallEnded(true);
            });
            client.on("error", (error) => {
                console.error("Retell error:", error);
                if (error.name === "NotAllowedError") {
                    alert("Microphone access was denied. Please allow microphone access and try again.");
                } else if (error.name === "NotFoundError") {
                    alert("No microphone was found. Please check your audio settings and try again.");
                }
                stopCall();
            });
        };

        setupEventListeners(retellWebClient);

        return () => {
            retellWebClient.removeAllListeners();
        };
    }, [retellWebClient, stopCall]);

    const CheckmarkIcon = useMemo(() => () => (
        <svg className="w-5 h-5 text-purple-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
        </svg>
    ), []);

    const LoadingSpinner = useMemo(() => () => (
        <svg className="animate-spin h-5 w-5 text-purple-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
    ), []);

    const isDefaultAgent = useMemo(() => agentID === DEFAULT_AGENT_ID, [agentID]);
    const hasCustomPracticeName = useMemo(() => practiceName && practiceName.toLowerCase() !== 'your practice', [practiceName]);

    const renderHeading = useCallback(() => {
        if (callInProgress) {
            return (
                <h1 className="text-xl sm:text-2xl md:text-3xl font-bold mb-4 sm:mb-6 text-gray-900">
                    You're talking to Kate the AI Assistant for <span className="text-purple-500">{vipConfig["company_name"]}</span>
                </h1>
            );
        }
        return (
            <h1 className="text-xl sm:text-2xl md:text-3xl font-bold mb-4 sm:mb-6 text-gray-900">
                Speak with <span className="text-purple-600">{vipConfig["company_name"]}'s</span> AI Assistant
            </h1>
        );
    }, [callInProgress, vipConfig]);

    const renderBulletPoints = useCallback(() => {
        if (!vipConfig || !vipConfig.tips || !Array.isArray(vipConfig.tips)) {
            return null; // or return a loading indicator or default content
        }

        return (
            <div className="border w-full border-gray-200 rounded-lg p-3 sm:p-4 mt-4 bg-gray-50 shadow-md relative">
            <span className="absolute -top-3 left-4 bg-purple-500 text-white px-2 py-1 text-xs sm:text-sm font-semibold rounded-full">
              Tips
            </span>
                {vipConfig.tips.map((tip, index) => (
                    <div key={index} className="flex items-center justify-start w-full mb-2 last:mb-0">
                        <CheckmarkIcon />
                        <p className="text-xs sm:text-sm text-gray-700">{tip}</p>
                    </div>
                ))}
            </div>
        );
    }, [vipConfig, CheckmarkIcon]);


    return (
        <div className=" text-gray-900 rounded-lg p-4    max-w-xs mx-auto overflow-hidden">
            {callInProgress && <RandomWaveViz isTalking={isTalking} />}
            <div className={`${isKateDesign ? 'scale-110 transform' : ''}`}>
                {(!callInProgress && !callEnded) &&  (<UserFormTable onFormSubmit = {handleFormSubmit} isLoading={isLoading} design = {design}/>)}
                { callInProgress && (
                    <button
                        className="w-full py-2 px-4 bg-gray-400 text-white font-semibold rounded-lg shadow-md mt-4 hover:bg-gray-500 transition duration-300"
                        onClick={stopCall}
                    >
                        End Call
                    </button>
                )}
                {
                    callEnded && !finalFormSubmitted && (
                        <PreFilledForm onFormSubmit2={handleFinalFormSubmit} formSubmitted={submittedData} isLoading={isLoading} />
                    )
                }
            </div>
        </div>
    );
};

export default React.memo(VIPCallCard);