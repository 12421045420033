import React, { useState, useEffect } from 'react';

const PreFilledForm = ({ onFormSubmit2, formSubmitted, isLoading }) => {
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    countryCode: '+1'
  });
  const [errors, setErrors] = useState({});
  
  // Initialize name and phone number from formSubmitted
  useEffect(() => {
    if (formSubmitted) {
      setFormData({
        name: formSubmitted.name || '',
        phoneNumber: (formSubmitted.countryCode + formSubmitted.phoneNumber) || '',
        countryCode:formSubmitted.countryCode || '+1',
        email: ''
      });
    }
  }, [formSubmitted]);

  const validatePhoneNumber = (phoneNumber) => {
    // Regex pattern to validate exactly 10 digits
    const phonePattern = /^\d{10}$/;
    return phonePattern.test(phoneNumber);
  };

  const validateEmail = (email) => {
    // Basic regex for email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validateForm = () => {
    const newErrors = {};

    // Check if email is valid
    if (!validateEmail(formData.email)) {
      newErrors.email = 'Invalid email address';
    }

    setErrors(newErrors);

    // If there are no errors, the form is valid
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate form before submitting
    if (!validateForm()) {
      return; // Stop submission if validation fails
    }

    // Call the parent onFormSubmit function after validation
    if (onFormSubmit2) {
      onFormSubmit2(formData); // Pass the form data to the parent component
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <input
          type="text"
          name="name"
          value={formData.name}
          readOnly // Name is read-only
          className="mb-4 py-2 px-4 border rounded-lg w-full max-w-md bg-gray-100"
        />
      </div>
      <div>
        <input
          type="tel"
          name="phoneNumber"
          value={formData.phoneNumber}
          readOnly // Phone number is read-only
          className="mb-4 py-2 px-4 border rounded-lg w-full max-w-md bg-gray-100"
        />
      </div>
      <div>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          placeholder="Enter your email"
          className="mb-4 py-2 px-4 border rounded-lg w-full max-w-md"
        />
        {errors.email && (
          <p className="text-red-500">{errors.email}</p>
        )}
      </div>
      <button 
        type="submit" 
        className={`py-2 px-4 text-white rounded ${isLoading ? 'bg-green-500' : 'bg-green-700'}`} // Change color when loading
        disabled={isLoading} // Disable button while loading
      >
        {isLoading ? 'Submitting...' : 'Submit'}
      </button>
    </form>
  );
};

export default PreFilledForm;