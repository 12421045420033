const allCountryCodes = [
    { code: '+93', name: 'AFG' },   // Afghanistan
    { code: '+355', name: 'ALB' },  // Albania
    { code: '+213', name: 'DZA' },  // Algeria
    { code: '+376', name: 'AND' },  // Andorra
    { code: '+244', name: 'AGO' },  // Angola
    { code: '+54', name: 'ARG' },   // Argentina
    { code: '+374', name: 'ARM' },  // Armenia
    { code: '+61', name: 'AUS' },   // Australia
    { code: '+43', name: 'AUT' },   // Austria
    { code: '+994', name: 'AZE' },  // Azerbaijan
    { code: '+973', name: 'BHR' },  // Bahrain
    { code: '+880', name: 'BGD' },  // Bangladesh
    { code: '+375', name: 'BLR' },  // Belarus
    { code: '+32', name: 'BEL' },   // Belgium
    { code: '+501', name: 'BLZ' },  // Belize
    { code: '+229', name: 'BEN' },  // Benin
    { code: '+975', name: 'BTN' },  // Bhutan
    { code: '+591', name: 'BOL' },  // Bolivia
    { code: '+387', name: 'BIH' },  // Bosnia and Herzegovina
    { code: '+267', name: 'BWA' },  // Botswana
    { code: '+55', name: 'BRA' },   // Brazil
    { code: '+673', name: 'BRN' },  // Brunei
    { code: '+359', name: 'BGR' },  // Bulgaria
    { code: '+226', name: 'BFA' },  // Burkina Faso
    { code: '+257', name: 'BDI' },  // Burundi
    { code: '+855', name: 'KHM' },  // Cambodia
    { code: '+237', name: 'CMR' },  // Cameroon
    { code: '+1', name: 'USA/CAN' },    // Canada
    { code: '+238', name: 'CPV' },  // Cape Verde
    { code: '+236', name: 'CAF' },  // Central African Republic
    { code: '+235', name: 'TCD' },  // Chad
    { code: '+56', name: 'CHL' },   // Chile
    { code: '+86', name: 'CHN' },   // China
    { code: '+57', name: 'COL' },   // Colombia
    { code: '+269', name: 'COM' },  // Comoros
    { code: '+242', name: 'COG' },  // Congo
    { code: '+243', name: 'COD' },  // Congo (Democratic Republic)
    { code: '+506', name: 'CRI' },  // Costa Rica
    { code: '+225', name: 'CIV' },  // Côte d'Ivoire
    { code: '+385', name: 'HRV' },  // Croatia
    { code: '+53', name: 'CUB' },   // Cuba
    { code: '+357', name: 'CYP' },  // Cyprus
    { code: '+420', name: 'CZE' },  // Czech Republic
    { code: '+45', name: 'DNK' },   // Denmark
    { code: '+253', name: 'DJI' },  // Djibouti
    { code: '+1 767', name: 'DMA' },// Dominica
    { code: '+1 809', name: 'DOM' },// Dominican Republic
    { code: '+670', name: 'TLS' },  // East Timor (Timor-Leste)
    { code: '+593', name: 'ECU' },  // Ecuador
    { code: '+20', name: 'EGY' },   // Egypt
    { code: '+503', name: 'SLV' },  // El Salvador
    { code: '+240', name: 'GNQ' },  // Equatorial Guinea
    { code: '+291', name: 'ERI' },  // Eritrea
    { code: '+372', name: 'EST' },  // Estonia
    { code: '+251', name: 'ETH' },  // Ethiopia
    { code: '+679', name: 'FJI' },  // Fiji
    { code: '+358', name: 'FIN' },  // Finland
    { code: '+33', name: 'FRA' },   // France
    { code: '+241', name: 'GAB' },  // Gabon
    { code: '+220', name: 'GMB' },  // Gambia
    { code: '+995', name: 'GEO' },  // Georgia
    { code: '+49', name: 'DEU' },   // Germany
    { code: '+233', name: 'GHA' },  // Ghana
    { code: '+30', name: 'GRC' },   // Greece
    { code: '+502', name: 'GTM' },  // Guatemala
    { code: '+224', name: 'GIN' },  // Guinea
    { code: '+245', name: 'GNB' },  // Guinea-Bissau
    { code: '+592', name: 'GUY' },  // Guyana
    { code: '+509', name: 'HTI' },  // Haiti
    { code: '+504', name: 'HND' },  // Honduras
    { code: '+36', name: 'HUN' },   // Hungary
    { code: '+354', name: 'ISL' },  // Iceland
    { code: '+91', name: 'IND' },   // India
    { code: '+62', name: 'IDN' },   // Indonesia
    { code: '+98', name: 'IRN' },   // Iran
    { code: '+964', name: 'IRQ' },  // Iraq
    { code: '+353', name: 'IRL' },  // Ireland
    { code: '+972', name: 'ISR' },  // Israel
    { code: '+39', name: 'ITA' },   // Italy
    { code: '+1 876', name: 'JAM' },// Jamaica
    { code: '+81', name: 'JPN' },   // Japan
    { code: '+962', name: 'JOR' },  // Jordan
    { code: '+7', name: 'KAZ' },    // Kazakhstan
    { code: '+254', name: 'KEN' },  // Kenya
    { code: '+686', name: 'KIR' },  // Kiribati
    { code: '+965', name: 'KWT' },  // Kuwait
    { code: '+996', name: 'KGZ' },  // Kyrgyzstan
    { code: '+856', name: 'LAO' },  // Laos
    { code: '+371', name: 'LVA' },  // Latvia
    { code: '+961', name: 'LBN' },  // Lebanon
    { code: '+266', name: 'LSO' },  // Lesotho
    { code: '+231', name: 'LBR' },  // Liberia
    { code: '+218', name: 'LBY' },  // Libya
    { code: '+423', name: 'LIE' },  // Liechtenstein
    { code: '+370', name: 'LTU' },  // Lithuania
    { code: '+352', name: 'LUX' },  // Luxembourg
    { code: '+261', name: 'MDG' },  // Madagascar
    { code: '+265', name: 'MWI' },  // Malawi
    { code: '+60', name: 'MYS' },   // Malaysia
    { code: '+960', name: 'MDV' },  // Maldives
    { code: '+223', name: 'MLI' },  // Mali
    { code: '+356', name: 'MLT' },  // Malta
    { code: '+692', name: 'MHL' },  // Marshall Islands
    { code: '+222', name: 'MRT' },  // Mauritania
    { code: '+230', name: 'MUS' },  // Mauritius
    { code: '+52', name: 'MEX' },   // Mexico
    { code: '+691', name: 'FSM' },  // Micronesia
    { code: '+373', name: 'MDA' },  // Moldova
    { code: '+377', name: 'MCO' },  // Monaco
    { code: '+976', name: 'MNG' },  // Mongolia
    { code: '+382', name: 'MNE' },  // Montenegro
    { code: '+212', name: 'MAR' },  // Morocco
    { code: '+258', name: 'MOZ' },  // Mozambique
    { code: '+95', name: 'MMR' },   // Myanmar
    { code: '+264', name: 'NAM' },  // Namibia
    { code: '+674', name: 'NRU' },  // Nauru
    { code: '+977', name: 'NPL' },  // Nepal
    { code: '+31', name: 'NLD' },   // Netherlands
    { code: '+64', name: 'NZL' },   // New Zealand
    { code: '+505', name: 'NIC' },  // Nicaragua
    { code: '+227', name: 'NER' },  // Niger
    { code: '+234', name: 'NGA' },  // Nigeria
    { code: '+47', name: 'NOR' },   // Norway
    { code: '+968', name: 'OMN' },  // Oman
    { code: '+92', name: 'PAK' },   // Pakistan
    { code: '+680', name: 'PLW' },  // Palau
    { code: '+507', name: 'PAN' },  // Panama
    { code: '+675', name: 'PNG' },  // Papua New Guinea
    { code: '+595', name: 'PRY' },  // Paraguay
    { code: '+51', name: 'PER' },   // Peru
    { code: '+63', name: 'PHL' },   // Philippines
    { code: '+48', name: 'POL' },   // Poland
    { code: '+351', name: 'PRT' },  // Portugal
    { code: '+974', name: 'QAT' },  // Qatar
    { code: '+40', name: 'ROU' },   // Romania
    { code: '+7', name: 'RUS' },    // Russia
    { code: '+250', name: 'RWA' },  // Rwanda
    { code: '+1 758', name: 'LCA' },// Saint Lucia
    { code: '+685', name: 'WSM' },  // Samoa
    { code: '+378', name: 'SMR' },  // San Marino
    { code: '+239', name: 'STP' },  // Sao Tome and Principe
    { code: '+966', name: 'SAU' },  // Saudi Arabia
    { code: '+221', name: 'SEN' },  // Senegal
    { code: '+381', name: 'SRB' },  // Serbia
    { code: '+248', name: 'SYC' },  // Seychelles
    { code: '+232', name: 'SLE' },  // Sierra Leone
    { code: '+65', name: 'SGP' },   // Singapore
    { code: '+421', name: 'SVK' },  // Slovakia
    { code: '+386', name: 'SVN' },  // Slovenia
    { code: '+677', name: 'SLB' },  // Solomon Islands
    { code: '+252', name: 'SOM' },  // Somalia
    { code: '+27', name: 'ZAF' },   // South Africa
    { code: '+82', name: 'KOR' },   // South Korea
    { code: '+211', name: 'SSD' },  // South Sudan
    { code: '+34', name: 'ESP' },   // Spain
    { code: '+94', name: 'LKA' },   // Sri Lanka
    { code: '+249', name: 'SDN' },  // Sudan
    { code: '+597', name: 'SUR' },  // Suriname
    { code: '+268', name: 'SWZ' },  // Swaziland
    { code: '+46', name: 'SWE' },   // Sweden
    { code: '+41', name: 'CHE' },   // Switzerland
    { code: '+963', name: 'SYR' },  // Syria
    { code: '+886', name: 'TWN' },  // Taiwan
    { code: '+992', name: 'TJK' },  // Tajikistan
    { code: '+255', name: 'TZA' },  // Tanzania
    { code: '+66', name: 'THA' },   // Thailand
    { code: '+228', name: 'TGO' },  // Togo
    { code: '+676', name: 'TON' },  // Tonga
    { code: '+1 868', name: 'TTO' },// Trinidad and Tobago
    { code: '+216', name: 'TUN' },  // Tunisia
    { code: '+90', name: 'TUR' },   // Turkey
    { code: '+993', name: 'TKM' },  // Turkmenistan
    { code: '+688', name: 'TUV' },  // Tuvalu
    { code: '+256', name: 'UGA' },  // Uganda
    { code: '+380', name: 'UKR' },  // Ukraine
    { code: '+971', name: 'ARE' },  // United Arab Emirates
    { code: '+44', name: 'GBR' },   // United Kingdom
    { code: '+598', name: 'URY' },  // Uruguay
    { code: '+998', name: 'UZB' },  // Uzbekistan
    { code: '+678', name: 'VUT' },  // Vanuatu
    { code: '+379', name: 'VAT' },  // Vatican
    { code: '+58', name: 'VEN' },   // Venezuela
    { code: '+84', name: 'VNM' },   // Vietnam
    { code: '+967', name: 'YEM' },  // Yemen
    { code: '+260', name: 'ZMB' },  // Zambia
    { code: '+263', name: 'ZWE' }   // Zimbabwe
  ];

  module.exports ={allCountryCodes}
  