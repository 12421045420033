import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";
import DentalUpgradeCard from './DentalCardUpgrade';


const DEFAULT_PHONE_NUMBER = '+14152334023';




const CustomPracticeCall = ({ customNumber, agentID, isLoading }) => {
    const navigate = useNavigate();
    const [practiceName, setPracticeName] = useState('');
    const location = useLocation();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [websiteUrl, setWebsiteUrl] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [rating, setRating] = useState(0);
    const [showAdditionalContent, setShowAdditionalContent] = useState(false);
    const additionalContentRef = useRef(null);
    const [design, setDesign] = useState('');
    const [feedback_redirect, setFeedBackRedirect] = useState('');
    const [label, setLabel] = useState('');






    // New state for DentalUpgradeCard
    const [isCardExpanded, setIsCardExpanded] = useState(false);
    const [hasTriggeredConfetti, setHasTriggeredConfetti] = useState(false);
    
    const isDefaultNumber = customNumber === DEFAULT_PHONE_NUMBER;

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
      const checkMobile = () => {
        setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
      };
      checkMobile();
      window.addEventListener('resize', checkMobile);
      return () => window.removeEventListener('resize', checkMobile);
    }, []);
  

    useEffect(() => {
        window.scrollTo(0, 0);

        const query = new URLSearchParams(location.search);
        const practiceNameParam = query.get('practice_name');
        const nameParam = query.get('name');
        const emailParam = query.get('email');
        const websiteParam = query.get('website');
        const mobileParam = query.get('mobile');
        const designParam = query.get('design')
        const feedback_redirectParam = query.get('feedback_redirect');
        const labelParam = query.get('label');


        
        if (practiceNameParam) setPracticeName(decodeURIComponent(practiceNameParam));
        if (nameParam) setName(decodeURIComponent(nameParam));
        if (emailParam) setEmail(decodeURIComponent(emailParam));
        if (websiteParam) setWebsiteUrl(decodeURIComponent(websiteParam));
        if (mobileParam) setMobileNumber(decodeURIComponent(mobileParam));
        if (designParam) setDesign(decodeURIComponent(designParam));
        if (feedback_redirectParam) setFeedBackRedirect(decodeURIComponent(feedback_redirectParam));
        if (labelParam) setLabel(decodeURIComponent(labelParam));


        const handleScroll = () => {
            if (additionalContentRef.current) {
                const rect = additionalContentRef.current.getBoundingClientRect();
                if (rect.top <= window.innerHeight * 0.8) {
                    setShowAdditionalContent(true);
                }
            }
        };
      
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [location.search]);

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/customcall",
            title: "Call Custom AI Assistant"
        });
    }, []);

    const handleCallClick = (e) => {
        ReactGA.event({
            category: "User Interaction",
            action: "Click",
            label: "Call Custom AI VIP Assistant"
        });
        if (!isMobile) {
            e.preventDefault();
            return;
          }
      
        window.location.href = `tel:${customNumber}`;
    };


    const formatPhoneNumber = (phoneNumber) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            const intlCode = (match[1] ? '+1 ' : '');
            return ['(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return phoneNumber;
    };




    useEffect(() => {
        const script1 = document.createElement('script');
        script1.src = 'https://fast.wistia.com/embed/medias/twrn63czfj.jsonp';
        script1.async = true;
        document.body.appendChild(script1);
    
        const script2 = document.createElement('script');
        script2.src = 'https://fast.wistia.com/assets/external/E-v1.js';
        script2.async = true;
        document.body.appendChild(script2);
    
        return () => {
          document.body.removeChild(script1);
          document.body.removeChild(script2);
        };
      }, []);
    
      useEffect(() => {
        if (window._wq) {
          window._wq = window._wq || [];
          window._wq.push({
            id: 'mh313ag1y4',
            options: {
                playerColor: "#7128d7",
                stillUrl: "https://fast.wistia.com/embed/medias/twrn63czfj/swatch"
              },              
            onReady: (video) => {
              video.bind('play', () => {
                ReactGA.event({
                  category: 'Video',
                  action: 'Play',
                  label: 'AI Teammates for Dentists'
                });
              });
    
              video.bind('pause', () => {
                ReactGA.event({
                  category: 'Video',
                  action: 'Pause',
                  label: 'AI Teammates for Dentists'
                });
              });
    
              video.bind('end', () => {
                ReactGA.event({
                  category: 'Video',
                  action: 'Ended',
                  label: 'AI Teammates for Dentists'
                });
              });
    
              video.bind('percentwatchedchanged', (percent) => {
                if (percent >= 25 && !video.reached25) {
                  video.reached25 = true;
                  ReactGA.event({
                    category: 'Video',
                    action: 'Progress 25%',
                    label: 'AI Teammates for Dentists'
                  });
                }
                if (percent >= 50 && !video.reached50) {
                  video.reached50 = true;
                  ReactGA.event({
                    category: 'Video',
                    action: 'Progress 50%',
                    label: 'AI Teammates for Dentists'
                  });
                }
                if (percent >= 75 && !video.reached75) {
                  video.reached75 = true;
                  ReactGA.event({
                    category: 'Video',
                    action: 'Progress 75%',
                    label: 'AI Teammates for Dentists'
                  });
                }
              });
            }
          });
        }
      }, []);


        


    const LoadingContent = () => (
        <div className="fixed inset-0 flex items-center justify-center bg-[#7128d7] bg-opacity-5">
            <div className="text-center">
            <h1 className="text-xl sm:text-3xl md:text-4xl font-bold text-[#7128d7] mb-8">
                {isDefaultNumber ? "Loading Yobi AI..." : "Loading your AI Agent..."}
            </h1>
                <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-[#7128d7] mx-auto"></div>
            </div>
        </div>
    );

    const MainContent = () => (
        <div className="h-full  bg-opacity-5 text-center flex flex-col ">
            <div className="flex-grow flex flex-col justify-start ">

                <div className="max-w-4xl flex flex-col  h-full justify-center text-center items-center  ">
                    {/* <h1 className="text-xl sm:text-3xl md:text-4xl font-bold text-[#7128d7] mb-8">
                        Call your AI Receptionist for {practiceName || 'your Practice'}
                    </h1> */}
                    <DentalUpgradeCard 
                        practiceName={practiceName || 'Your Practice'}
                        phoneNumber={formatPhoneNumber(customNumber)}
                        isExpanded={isCardExpanded}
                        setIsExpanded={setIsCardExpanded}
                        hasTriggeredConfetti={hasTriggeredConfetti}
                        setHasTriggeredConfetti={setHasTriggeredConfetti}
                        isCustomPractice={!isDefaultNumber && practiceName}
                        agentID={agentID}
                        design={design}
                        feedback_redirect={feedback_redirect}
                        label={label}

                    />
                </div>
            </div>
            

        </div>
    );
        
    return isLoading ? <LoadingContent /> : <MainContent />;
};

export default CustomPracticeCall;