// FAQItem.js
import React, { useState, useRef, useLayoutEffect } from 'react';



const FAQItem = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);
    const contentRef = useRef(null);

    useLayoutEffect(() => {
        if (isOpen) {
            const contentEl = contentRef.current;
            setContentHeight(contentEl.scrollHeight*1.5);
        } else {
            setContentHeight(0);
        }
    }, [isOpen]);

    return (
        <div className="py-4">
            <button
                className="flex justify-between items-center w-full text-left"
                onClick={() => setIsOpen(!isOpen)}
            >
                <span className="text-xl font-semibold text-[#7128d7]">{question}</span>
                <svg
                    className={`w-6 h-6 text-[#7128d7] transform transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                </svg>
            </button>
            <div 
                className="overflow-hidden transition-all duration-300 ease-in-out"
                style={{ maxHeight: isOpen ? `${contentHeight}px` : '0px' }}
            >
                <div ref={contentRef}>
                    <p className="mt-2 text-gray-600">{answer}</p>
                </div>
            </div>
        </div>
    );
};

export default FAQItem;
