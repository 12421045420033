import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { RetellWebClient } from 'retell-client-js-sdk';
import RandomWaveViz from './RandomWaveViz';
import StarRating from './StarRating';
import ReactGA from "react-ga4";
import { useNavigate } from 'react-router-dom';

const DEFAULT_PHONE_NUMBER = '+14152334023';
const DEFAULT_AGENT_ID = '893c8f270b4a5fb7bebc5146910b420e';

const DentalUpgradeCard = ({
  practiceName,
  phoneNumber,
  isExpanded,
  setIsExpanded,
  hasTriggeredConfetti,
  setHasTriggeredConfetti,
  isCustomPractice,
  agentID,
  design,
  feedback_redirect,
  label
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [retellWebClient, setRetellWebClient] = useState(() => new RetellWebClient());
  const [retellStartCallData, setRetellStartCallData] = useState(null);
  const [callInProgress, setCallInProgress] = useState(false);
  const [isTalking, setIsTalking] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const hasFetchedRef = useRef(false);
  const [rating, setRating] = useState(0);
  const [callEnded, setCallEnded] = useState(false);
  const navigate = useNavigate();
  const [isPopupVisible, setPopupVisible] = useState(false);

  const getColorScheme = () => {
    switch (design) {
      case 'blue':
        return {
          primary: 'blue',
          button: 'bg-blue-600 hover:bg-blue-700',
          text: 'text-blue-600',
          border: 'border-blue-200',
          tipsBg: 'bg-blue-500'
        };
      case 'green':
        return {
          primary: 'green',
          button: 'bg-green-600 hover:bg-green-700',
          text: 'text-green-600',
          border: 'border-green-200',
          tipsBg: 'bg-green-500'
        };
      case 'orange':
        return {
          primary: 'orange',
          button: 'bg-[#eb9637] hover:bg-[#d88730]',
          text: 'text-[#eb9637]',
          border: 'border-[#f3c190]',
          tipsBg: 'bg-[#eb9637]'
        };
      case 'navy':
        return {
          primary: 'navy',
          button: 'bg-[#070468] hover:bg-[#05034d]',
          text: 'text-[#070468]',
          border: 'border-[#9090a3]',
          tipsBg: 'bg-[#070468]'
        };
      default:
        return {
          primary: 'purple',
          button: 'bg-purple-900 hover:bg-purple-800',
          text: 'text-purple-600',
          border: 'border-purple-200',
          tipsBg: 'bg-purple-500'
        };
    }
  };

  const colorScheme = getColorScheme();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/customcall",
      title: "Call Custom AI VIP Assistant"
    });
  }, []);

  const getAiRole = useCallback(() => {
    return ['blue', 'navy', 'orange'].includes(design) ? 'Receptionist' : 'Assistant';
  }, [design]);


  const fetchRetellCallData = useCallback(async () => {
    if (hasFetchedRef.current) return retellStartCallData;
    try {
      const apiKey = process.env.REACT_APP_RETELL_API_KEY;
      const url = 'https://api.retellai.com/v2/create-web-call';
      const headers = {
        'Authorization': `Bearer ${apiKey}`,
        'Content-Type': 'application/json'
      };
      let body = { agent_id: agentID };

      if (label) {
          body.metadata = { label: label };
      } else if (['blue', 'navy', 'orange'].includes(design)) {
          body.metadata = { label: "gtm" };
      }
            
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setRetellStartCallData(data);
      hasFetchedRef.current = true;
      return data;
    } catch (error) {
      console.error("Error fetching Retell call data:", error);
      return null;
    }
  }, [agentID, design, label, retellStartCallData]);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const checkAudioPermissions = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      return true;
    } catch (err) {
      console.error("Error accessing microphone:", err);
      return true;
    }
  };

  const handleCallClick = async () => {
    if (callInProgress || isLoading) return;

    const hasPermission = await checkAudioPermissions();
    if (!hasPermission) {
      const confirmCall = window.confirm("No microphone detected. Would you like to call the phone number instead?");
      if (confirmCall) {
        window.location.href = `tel:${phoneNumber || DEFAULT_PHONE_NUMBER}`;
      }
      return;
    }

    try {
      setIsLoading(true);

      const newRetellWebClient = new RetellWebClient();
      setRetellWebClient(newRetellWebClient);

      const data = await fetchRetellCallData();
      if (!data || !data.access_token) {
        throw new Error("Failed to obtain access token");
      }

      await newRetellWebClient.startCall({
        accessToken: data.access_token,
      });

      setCallInProgress(true);
    } catch (error) {
      console.error("Error starting call:", error);
      alert("There was an error starting the call. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const stopCall = useCallback(() => {
    try {
      retellWebClient.stopCall();
      setCallInProgress(false);
      setIsTalking(false);
      hasFetchedRef.current = false;
      setCallEnded(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    } catch (error) {
      console.error("Error stopping call:", error);
    }
  }, [retellWebClient]);

  const handleRatingSubmit = (rating, feedback) => {
    ReactGA.event({
      category: "User Interaction",
      action: "Rating Submitted",
      label: `Rating: ${rating}, Feedback: ${feedback.slice(0, 50)}...`
    });
    
    if (feedback_redirect && feedback_redirect !== '') {
      let url = feedback_redirect;
      // Check if the URL starts with http:// or https://
      if (!/^https?:\/\//i.test(url)) {
        url = 'https://' + url;
      }
      window.open(url, '_blank');
    } else {
      setPopupVisible(true);
    }
  };

  const closePopup = () => {
    setPopupVisible(false);
  };

  useEffect(() => {
    const setupEventListeners = (client) => {
      client.on("agent_start_talking", () => setIsTalking(true));
      client.on("agent_stop_talking", () => setIsTalking(false));
      client.on("call_started", () => console.log("call started"));
      client.on("call_ended", () => {
        console.log("call ended");
        setCallInProgress(false);
        setIsTalking(false);
        setCallEnded(true);
      });
      client.on("error", (error) => {
        console.error("Retell error:", error);
        if (error.name === "NotAllowedError") {
          alert("Microphone access was denied. Please allow microphone access and try again.");
        } else if (error.name === "NotFoundError") {
          alert("No microphone was found. Please check your audio settings and try again.");
        }
        stopCall();
      });
    };

    setupEventListeners(retellWebClient);

    return () => {
      retellWebClient.removeAllListeners();
    };
  }, [retellWebClient, stopCall]);

  const CheckmarkIcon = useMemo(() => () => (
    <svg className={`w-5 h-5 ${colorScheme.text} mr-2`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
    </svg>
  ), [colorScheme]);

  const LoadingSpinner = useMemo(() => () => (
    <svg className={`animate-spin h-5 w-5 ${colorScheme.text}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  ), [colorScheme]);

  const isDefaultAgent = useMemo(() => agentID === DEFAULT_AGENT_ID, [agentID]);
  const hasCustomPracticeName = useMemo(() => practiceName && practiceName.toLowerCase() !== 'your practice', [practiceName]);

  
  const renderHeading = useCallback(() => {
    const aiRole = getAiRole();
    
    if (callInProgress) {
      if (!isDefaultAgent) {
        return (
          <h1 className="text-3xl font-bold mb-6 text-gray-900">
            You're talking to Kate the AI {aiRole} for <span className={colorScheme.text}>{practiceName || 'Yobi Smiles'}</span>
          </h1>
        );
      } else {
        return (
          <h1 className="text-3xl font-bold mb-6 text-gray-900">
            You're talking to Kate the AI {aiRole} for <span className={colorScheme.text}>{'Yobi Smiles'}</span>
          </h1>
        );  
      }
    }
    
    if (hasCustomPracticeName && !isDefaultAgent) {
      return (
        <h1 className="text-3xl font-bold mb-6 text-gray-900">
          Speak to the AI {aiRole} for <span className={colorScheme.text}>{practiceName}</span>
        </h1>
      );
    } else if (hasCustomPracticeName && isDefaultAgent) {
      return (
        <h1 className="text-3xl font-bold mb-6 text-gray-900">
          See how Yobi can help <span className={colorScheme.text}>{practiceName}</span> by talking with Kate, our AI Dental {aiRole}
        </h1>
      );
    } else if (!hasCustomPracticeName && !isDefaultAgent) {
      return (
        <h1 className="text-3xl font-bold mb-6 text-gray-900">
          Speak with Your AI Dental {aiRole}
        </h1>
      );
    } else {
      return (
        <h1 className="text-3xl font-bold mb-6 text-gray-900">
          Talk with Kate to learn more
        </h1>
      );
    }
  }, [hasCustomPracticeName, isDefaultAgent, practiceName, callInProgress, colorScheme, design]);

  const renderBulletPoints = useCallback(() => {
    return (
      <div className={`border ${colorScheme.border} rounded-lg p-4 mt-4 bg-gray-50 shadow-md relative`}>
        <span className={`absolute -top-3 left-4 ${colorScheme.tipsBg} text-white px-2 py-1 text-sm font-semibold rounded-full`}>
          Tips
        </span>
        <div className="space-y-2">
          <div className="flex items-center">
            <CheckmarkIcon />
            <p className="text-sm text-gray-700">
              {['blue', 'navy', 'orange'].includes(design) 
                ? "Ask About Our Services" 
                : "Ask About Website Content"}
            </p>
          </div>
          <div className="flex items-center">
            <CheckmarkIcon />
            <p className="text-sm text-gray-700">Ask About {practiceName}</p>
          </div>
          <div className="flex items-center">
            <CheckmarkIcon />
            <p className="text-sm text-gray-700">
              Leave Contact Info For a Follow Up
            </p>
          </div>
        </div>
      </div>
    );
  }, [practiceName, CheckmarkIcon, colorScheme]);

  const getButtonStyles = () => {
    const baseStyles = "py-3 px-6 font-semibold rounded-lg shadow-md w-full max-w-md mx-auto flex items-center justify-center text-white";
    return `${baseStyles} ${colorScheme.button}`;
  };

  return (
    <div className="bg-gray-50 text-gray-900 flex flex-col justify-center h-full rounded-lg p-6 shadow-lg border border-gray-200">
      {isPopupVisible && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-4 md:p-6 rounded-lg shadow-lg text-center w-11/12 max-w-sm md:max-w-md">
            <h2 className="text-lg md:text-xl font-bold mb-4">Thank You for Your Feedback</h2>
            <button
              onClick={closePopup}
              className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {renderHeading()}
      {callInProgress ? <RandomWaveViz isTalking={isTalking}/> : <div></div>}

      {!callInProgress && (
        <button
          className={getButtonStyles()}
          onClick={handleCallClick}
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <LoadingSpinner />
              <span className="ml-2 text-lg sm:text-xl">Connecting...</span>
            </>
          ) : (
            <span className="text-lg sm:text-xl">Talk Now</span>
          )}
        </button>
      )}

      <div className="mt-8 space-y-4 flex flex-col items-center">
        <div className="space-y-4 mb-6">
          {renderBulletPoints()}
        </div>
        {callInProgress && (
          <button
            className="py-2 px-4 bg-gray-400 text-white font-semibold rounded-lg shadow-md mt-4 hover:bg-gray-500 transition duration-300"
            onClick={stopCall}
          >
            <span className="text-sm">End Call</span>
          </button>
        )}
      </div>

      {callEnded && (
        <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
          <h2 className={`text-3xl font-semibold ${colorScheme.text} mb-6`}>How did the call go?</h2>
          <StarRating
            rating={rating}
            setRating={setRating}
            onSubmit={handleRatingSubmit}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(DentalUpgradeCard);