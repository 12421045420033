import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";
import VIPSimpleCallCard from "./VIPSimpleCallCard";

const VIPPage = () => {
    const { vipId } = useParams();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [vipConfig, setVipConfig] = useState();
    const [label, setLabel] = useState('');
    const [design, setDesign] = useState('');
    const [contact, setContact] = useState('');

    const fetchVIPData = useCallback(async () => {
        console.log("Fetching Data ...");
        try {
            setIsLoading(true);
            const response = await fetch(`${process.env.REACT_APP_API_URL}/get_vip_config_by_name/${vipId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log(data);
            setVipConfig(data);
            return data;
        } catch (error) {
            console.error('Error fetching VIP data:', error);
        } finally {
            setIsLoading(false);
        }
    }, [vipId]);

    useEffect(() => {
        if (vipId) {
            fetchVIPData();
        }
    }, [fetchVIPData, vipId]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const designParam = searchParams.get('design'); // New: Get design parameter
        const labelParam = searchParams.get('label');
        const contactParam = searchParams.get('contact');
        if (labelParam) {
            setLabel(labelParam);
        }
        if (designParam) {
            setDesign(designParam); // New: Set design state
        }
        if (contactParam) {
            setContact(contactParam); // New: Set design state
        }
    }, [location.search]);

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/customcall",
            title: "Call Custom AI Dental Assistant"
        });
    }, []);

    useEffect(() => {
        const script1 = document.createElement('script');
        script1.src = 'https://fast.wistia.com/embed/medias/twrn63czfj.jsonp';
        script1.async = true;
        document.body.appendChild(script1);

        const script2 = document.createElement('script');
        script2.src = 'https://fast.wistia.com/assets/external/E-v1.js';
        script2.async = true;
        document.body.appendChild(script2);

        return () => {
            document.body.removeChild(script1);
            document.body.removeChild(script2);
        };
    }, []);

    useEffect(() => {
        if (window._wq) {
            window._wq = window._wq || [];
            window._wq.push({
                id: 'mh313ag1y4',
                options: {
                    playerColor: "#7128d7",
                    stillUrl: "https://fast.wistia.com/embed/medias/twrn63czfj/swatch"
                },
                onReady: (video) => {
                    video.bind('play', () => {
                        ReactGA.event({
                            category: 'Video',
                            action: 'Play',
                            label: 'AI Teammates for Dentists'
                        });
                    });

                    video.bind('pause', () => {
                        ReactGA.event({
                            category: 'Video',
                            action: 'Pause',
                            label: 'AI Teammates for Dentists'
                        });
                    });

                    video.bind('end', () => {
                        ReactGA.event({
                            category: 'Video',
                            action: 'Ended',
                            label: 'AI Teammates for Dentists'
                        });
                    });

                    video.bind('percentwatchedchanged', (percent) => {
                        if (percent >= 25 && !video.reached25) {
                            video.reached25 = true;
                            ReactGA.event({
                                category: 'Video',
                                action: 'Progress 25%',
                                label: 'AI Teammates for Dentists'
                            });
                        }
                        if (percent >= 50 && !video.reached50) {
                            video.reached50 = true;
                            ReactGA.event({
                                category: 'Video',
                                action: 'Progress 50%',
                                label: 'AI Teammates for Dentists'
                            });
                        }
                        if (percent >= 75 && !video.reached75) {
                            video.reached75 = true;
                            ReactGA.event({
                                category: 'Video',
                                action: 'Progress 75%',
                                label: 'AI Teammates for Dentists'
                            });
                        }
                    });
                }
            });
        }
    }, []);
    const LoadingContent = () => (
        <div className="fixed inset-0 flex items-center justify-center bg-[#7128d7] bg-opacity-5">
            <div className="text-center">
                <h1 className="text-xl sm:text-3xl md:text-4xl font-bold text-white mb-8">
                   Loading your AI Receptionist...
                </h1>
                <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-white mx-auto"></div>
            </div>
        </div>
    );

    const MainContent = () => (
        <div className="flex min-h-screen items-center justify-center p-4 bg-inherit">
            <div className="w-full max-w-xs">
                {vipConfig ? (
                    <VIPSimpleCallCard
                        vipId={vipId}
                        vipConfig={vipConfig}
                        label={label}  // Pass the label to VIPSimpleCallCard
                        design={design} // New: Pass design to VIPSimpleCallCard
                        contact={contact} // Pass the contact prop
                    />
                ) : (
                    <p>Loading VIP configuration...</p>
                )}
            </div>
        </div>
    );

    return isLoading ? <LoadingContent /> : <MainContent />;
};

export default VIPPage;

