import React, { useState, useEffect } from 'react';
import {Route, Routes, useLocation, useNavigate, Link, useParams} from 'react-router-dom';
import ReactGA from 'react-ga4';
import CustomPracticeCall from './CustomPracticeCall';
import CallAi from './CallAi';
import NoAi from './NoAi';
import TalkToYobiTeam from './TalkToYobiTeam';
import VIPPage from "./VIPPage";
import ConfigForm from "./ConfigForm";
import VIPSimplePage from "./VIPSimplePage";

const DEFAULT_PHONE_NUMBER = '+14152334023';
const DEFAULT_AGENT_ID = '893c8f270b4a5fb7bebc5146910b420e';

const AB_TEST_CONFIG = [
  { weight: 0.99, destination: 'https://yobi.app/' },
  { weight: 0.00, destination: 'https://receptionist.yobi.app/' },
  { weight: 0.00, destination: '/customcall' }
];

function LoadingScreen() {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-100">
      <div className="text-center">
        <h1 className="text-xl sm:text-2xl md:text-3xl font-semibold text-gray-800 mb-4">
          Loading...
        </h1>
        <div className="inline-block">
          <svg className="animate-spin h-8 w-8 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
      </div>
    </div>
  );
}

function App() {
  const [customNumber, setCustomNumber] = useState('');
  const [agentID, setAgentID] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [urlParams, setUrlParams] = useState({});
  const [hasCompletedRequest, setHasCompletedRequest] = useState(false);
  const [previousPage, setPreviousPage] = useState('');
  const [showBackButton, setShowBackButton] = useState(false);
  const { vipId } = useParams(); // Get the agentId from the URL

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize('G-BFQMXWSE8F');
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const params = {};
    for (let [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    setUrlParams(params);

    if (params.lpurl) {
      let prevPage = decodeURIComponent(params.lpurl);
      if (!prevPage.startsWith('http://') && !prevPage.startsWith('https://')) {
        prevPage = 'https://' + prevPage;
      }
      setPreviousPage(prevPage+`/?website=${params.website}&practice_name=${params.practice_name}`);
      setShowBackButton(true);
    } else {
      setShowBackButton(false);
    }

    const fetchCustomNumber = async () => {
      try {
        if(params.website){
          const response = await fetch(`${process.env.REACT_APP_API_URL}/setup_lead`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              url: params.website,
            }),
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();
          if (data.number && data.agent_id) {
            setCustomNumber(data.number);
            setAgentID(data.agent_id);
          } else {
            setCustomNumber(DEFAULT_PHONE_NUMBER);
            setAgentID(DEFAULT_AGENT_ID);
          }
        }

      } catch (error) {
        console.error('Error fetching custom number:', error);
        setCustomNumber(DEFAULT_PHONE_NUMBER);
        setAgentID(DEFAULT_AGENT_ID);
      } finally {
        setIsLoading(false);
        setHasCompletedRequest(true);
      }
    };
      
    fetchCustomNumber();
  }, []);

  useEffect(() => {
    if (hasCompletedRequest && customNumber && location.pathname === '/') {
      const performABTest = () => {
        const random = Math.random();
        let cumulativeWeight = 0;
        
        for (const option of AB_TEST_CONFIG) {
          cumulativeWeight += option.weight;
          if (random < cumulativeWeight) {
            const destinationUrl = new URL(option.destination, window.location.origin);
            Object.keys(urlParams).forEach(key => 
              destinationUrl.searchParams.append(key, urlParams[key])
            );
            destinationUrl.searchParams.append('phone', customNumber);

            if (option.destination.startsWith('http')) {
              window.location.href = destinationUrl.toString();
            } else {
              navigate(destinationUrl.pathname + destinationUrl.search);
            }
            break;
          }
        }
      };

      performABTest();
    }
  }, [hasCompletedRequest, customNumber, navigate, location.pathname, urlParams]);

  const handleLogoClick = () => {
    if (previousPage) {
      window.location.href = previousPage;
    }
  };

  if (isLoading || (location.pathname === '/' && (!hasCompletedRequest || !customNumber))) {
    return <LoadingScreen />;
  }
/*
<nav className="p-2 sm:p-4 md:p-6 bg-white flex justify-between items-center shadow-md">
        <div>
          <img src="yobi-logo.png" alt="Yobi Logo" className="h-6 sm:h-8 md:h-12" />
        </div>
      </nav>
           {!isLoading && showBackButton && (
        <div
          onClick={handleLogoClick}
          className="flex items-center cursor-pointer bg-purple-500 text-white px-4 py-2 font-bold hover:bg-purple-600 w-28 mx-4 mt-4 rounded-lg"
          title="Go back"
        >
          <svg width="24px" height="24px" viewBox="0 0 1024 1024" fill="#FFFFFF" className="icon" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <path d="M669.6 849.6c8.8 8 22.4 7.2 30.4-1.6s7.2-22.4-1.6-30.4l-309.6-280c-8-7.2-8-17.6 0-24.8l309.6-270.4c8.8-8 9.6-21.6 2.4-30.4-8-8.8-21.6-9.6-30.4-2.4L360.8 480.8c-27.2 24-28 64-0.8 88.8l309.6 280z" fill="#FFFFFF"/>
          </svg>
          <span className="mr-2 font-bold">Back</span>
        </div>
      )}
*/
  return (
    <div className="min-h-screen h-screen bg-inherit">
 

      <div className="flex flex-col items-center justify-center h-full">
        <Routes>
          <Route path="/customcall" element={<CustomPracticeCall customNumber={customNumber} agentID={agentID} isLoading={isLoading} urlParams={urlParams} />} />
          <Route path="/talk-to-yobi-team" element={<TalkToYobiTeam />} />
          <Route path="/:vipId" element={<VIPPage />} />
          <Route path="/simple/:vipId" element={<VIPSimplePage />} />
          <Route path="/config" element={<ConfigForm />} />

        </Routes>
      </div>
    </div>
  );
}

export default App;
