import React, { useEffect, useRef, useState } from 'react';

const RandomWaveViz = ({ isTalking }) => {
    const canvasRef = useRef(null);
    const containerRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    let phase = 0;

    useEffect(() => {
        const updateDimensions = () => {
            if (containerRef.current) {
                const { width } = containerRef.current.getBoundingClientRect();
                setDimensions({ width, height: width * 0.33 }); // Adjust the aspect ratio as needed
            }
        };

        updateDimensions();
        window.addEventListener('resize', updateDimensions);

        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        let animationFrameId;

        const drawSmoothWaves = () => {
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.fillStyle = 'rgba(113, 40, 215, 0.1)';
            context.fillRect(0, 0, canvas.width, canvas.height);

            const sliceWidth = (canvas.width * 1.0) / 100;
            const baseAmplitude = canvas.height / 4;
            const baseFrequency = 2;

            const drawWave = (amplitude, frequency, color, offsetPhase, direction) => {
                context.lineWidth = 2;
                context.strokeStyle = color;
                context.beginPath();

                let x = 0;
                for (let i = 0; i < 100; i++) {
                    const v = Math.sin(phase * direction + offsetPhase + (i * Math.PI) / 25 * frequency);
                    const y = (v * amplitude) + (canvas.height / 2);

                    if (i === 0) {
                        context.moveTo(x, y);
                    } else {
                        context.lineTo(x, y);
                    }

                    x += sliceWidth;
                }

                context.stroke();
            };

            // Draw two waves with different directions
            drawWave(baseAmplitude * 0.6, baseFrequency * 0.8, '#b08cef', Math.PI / 4, 1);
            drawWave(baseAmplitude * 0.6, baseFrequency * 0.8, '#b08cef', Math.PI / 4, -1);

            phase += 0.1;
            animationFrameId = requestAnimationFrame(drawSmoothWaves);
        };

        const drawFlatLine = () => {
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.fillStyle = 'rgba(113, 40, 215, 0.1)';
            context.fillRect(0, 0, canvas.width, canvas.height);

            context.strokeStyle = '#b08cef';
            context.lineWidth = 2;
            context.beginPath();
            context.moveTo(0, canvas.height / 2);
            context.lineTo(canvas.width, canvas.height / 2);
            context.stroke();
        };

        if (isTalking) {
            drawSmoothWaves();
        } else {
            drawFlatLine();
        }

        return () => {
            cancelAnimationFrame(animationFrameId);
        };
    }, [isTalking, dimensions]);

    return (
        <div ref={containerRef} className="w-full h-auto" style={{ aspectRatio: '3/1' }}>
            <canvas
                ref={canvasRef}
                width={dimensions.width}
                height={dimensions.height}
                style={{ width: '100%', height: '100%' }}
            />
        </div>
    );
};

export default RandomWaveViz;