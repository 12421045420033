import React, { useEffect, useState } from 'react';
import Cal, { getCalApi } from "@calcom/embed-react";
import ReactGA from "react-ga4";
import FAQItem from './FAQItem';

const FAQSection = ({ faqItems }) => (
    <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
        <h2 className="text-3xl font-semibold text-[#120022] mb-6">Frequently Asked Questions</h2>
        <div className="divide-y divide-[#7128d7] divide-opacity-20">
            {faqItems.map((item, index) => (
                <FAQItem 
                    key={index} 
                    question={item.question} 
                    answer={item.answer}
                />
            ))}
        </div>
    </div>
);

const TalkToYobiTeam = () => {
    const [isIframeReady, setIsIframeReady] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        ReactGA.send({
            hitType: "pageview",
            page: "/talk-to-yobi-team",
            title: "Talk to Yobi Team"
        });

        const checkIframe = setInterval(async () => {
            const iframe = document.querySelector('iframe[data-cal-link="team/yobi-team/ai-teammate-onboarding"]');
            if (iframe) {
                clearInterval(checkIframe);
                setIsIframeReady(true);
                try {
                    const cal = await getCalApi();
                    cal("ui", {"styles":{"branding":{"brandColor":"#7128d7"}}});
                } catch (error) {
                    console.error("Error initializing Cal API:", error);
                }
            }
        }, 100);

        return () => clearInterval(checkIframe);
    }, []);

    const handleCallClick = () => {
        ReactGA.event({
            category: "User Interaction",
            action: "Click",
            label: "Call Yobi Team"
        });
        window.location.href = 'tel:+14085993613';
    };

    const handleScheduleClick = () => {
        ReactGA.event({
            category: "User Interaction",
            action: "Click",
            label: "Schedule Call with Yobi Team"
        });
        window.location.href = 'https://cal.com/team/yobi-team/ai-teammate-onboarding/';
    };

    const CallButton = ({ text, onClick }) => (
        <button 
            onClick={onClick} 
            className="py-3 px-6 bg-green-500 text-white font-bold rounded-lg shadow-md hover:bg-green-600 transition duration-300 w-full max-w-md mx-auto"
        >
            <div className="flex flex-nowrap justify-center items-center gap-x-2">
                <span className="text-2xl sm:text-3xl">{text}</span>
            </div>
        </button>
    );

    const faqItems = [
        {
            question: "How can Yobi help my dental practice?",
            answer: "Yobi's AI Dental Receptionist can handle patient calls, schedule appointments 24/7, and answer common questions, helping you never miss a patient call and increase patient satisfaction."
        },
        {
            question: "Is it difficult to implement Yobi in my practice?",
            answer: "Not at all! Our team will guide you through a smooth implementation process, ensuring Yobi is tailored to your practice's specific needs."
        },
        {
            question: "Can patients still speak to a human if needed?",
            answer: "Absolutely! The AI can alert your staff when necessary, ensuring that patients always have the option to speak with a human."
        }
    ];

    return (
        <div className="min-h-screen bg-[#7128d7] bg-opacity-5 text-center py-12 px-4">
            <div className="max-w-4xl mx-auto">
                <h1 className="text-xl sm:text-3xl md:text-4xl font-bold text-[#7128d7] mb-4">
                    Claim your AI Receptionist
                </h1>
                {/* <p className="text-lg mb-8">We're excited to discuss how Yobi can enhance your dental practice.</p> */}
                
                <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
                    {/* <h2 className="text-2xl font-semibold text-[#7128d7] mb-4">Next Steps</h2> */}
                    {/* <p className="text-lg mb-6">Choose how you'd like to proceed:</p> */}
                    <div className="space-y-6">
                        {/* <div>
                            <h3 className="text-2xl font-semibold mb-2">Now</h3>
                            <CallButton text="Call (408) 599-3613" onClick={handleCallClick} />
                        </div> */}
                        <div>
                            <h3 className="text-2xl font-semibold mb-2">Schedule a Call to Claim your AI Receptionist</h3>
                            <div className="w-full h-[600px] border border-gray-200 rounded-lg overflow-hidden">
                                <Cal
                                    calLink="team/yobi-team/ai-teammate-onboarding"
                                    style={{width:"100%", height:"100%", overflow:"scroll"}}
                                    config={{layout: 'month_view'}}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-white rounded-lg shadow-lg p-8 mb-12">
                    <h2 className="text-3xl font-semibold text-[#7128d7] mb-6">Why Choose Yobi?</h2>
                    <ul className="space-y-4 text-left">
                        <li className="flex items-start">
                            <svg className="w-5 h-5 text-[#7128d7] mr-3 flex-shrink-0 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                            </svg>
                            <span className="text-lg">Never Miss a Patient's Call</span>
                        </li>
                        <li className="flex items-start">
                            <svg className="w-5 h-5 text-[#7128d7] mr-3 flex-shrink-0 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                            </svg>
                            <span className="text-lg">24/7 Appointment Scheduling</span>
                        </li>
                        <li className="flex items-start">
                            <svg className="w-5 h-5 text-[#7128d7] mr-3 flex-shrink-0 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                            </svg>
                            <span className="text-lg">Increase Patient Satisfaction</span>
                        </li>
                    </ul>
                </div>

                <FAQSection faqItems={faqItems} />
                
                <div className="bg-[#7128d7] text-white rounded-lg p-8 shadow-lg mb-12">
                    <p className="text-2xl mb-8">Have More Questions?</p>
                    <CallButton text="Call (408) 599-3613" onClick={handleCallClick} />
                </div>
            </div>
        </div>
    );
};

export default TalkToYobiTeam;
