import React, { useState, useEffect } from 'react';

const ConfigForm = () => {
    const [configs, setConfigs] = useState([]);
    const [currentConfig, setCurrentConfig] = useState({
        id: null,
        companyName: '',
        agentID: '',
        theirName: '',
        tips: ['']
    });

    useEffect(() => {
        fetchConfigs();
    }, []);

    const fetchConfigs = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/get_vip_configs`);
            const data = await response.json();
            setConfigs(data);
        } catch (error) {
            console.error('Error fetching configurations:', error);
        }
    };

    const handleTipsChange = (index, value) => {
        const newTips = [...currentConfig.tips];
        newTips[index] = value;
        setCurrentConfig({ ...currentConfig, tips: newTips });
    };

    const handleAddTip = () => {
        setCurrentConfig({ ...currentConfig, tips: [...currentConfig.tips, ''] });
    };

    const handleRemoveTip = (index) => {
        const newTips = currentConfig.tips.filter((_, i) => i !== index);
        setCurrentConfig({ ...currentConfig, tips: newTips });
    };

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setCurrentConfig({ ...currentConfig, [id]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { id, companyName, agentID, theirName, tips } = currentConfig;

        try {
            if (id) {
                await fetch(`${process.env.REACT_APP_API_URL}/update_vip_config/${id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ companyName, agentID, theirName, tips })
                });
            } else {
                await fetch(`${process.env.REACT_APP_API_URL}/add_vip_config`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ companyName, agentID, theirName, tips })
                });
            }
            fetchConfigs();
            setCurrentConfig({ id: null, companyName: '', agentID: '', theirName: '', tips: [''] });
        } catch (error) {
            console.error('Error saving configuration:', error);
        }
    };

    const handleEdit = (config) => {
        setCurrentConfig(config);
    };

    const handleDelete = async (id) => {
        try {
            await fetch(`${process.env.REACT_APP_API_URL}/delete_vip_config/${id}`, {
                method: 'DELETE'
            });
            fetchConfigs();
        } catch (error) {
            console.error('Error deleting configuration:', error);
        }
    };

    return (
        <div className="p-6 max-w-screen-lg w-full mx-auto bg-white rounded-lg shadow-lg overflow-auto">
            <h2 className="text-2xl font-semibold mb-4">Config Form</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="companyName">
                        Company Name
                    </label>
                    <input
                        type="text"
                        id="companyName"
                        value={currentConfig.companyName}
                        onChange={handleInputChange}
                        className="w-full p-2 border border-gray-300 rounded-lg"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="agentID">
                        Agent ID
                    </label>
                    <input
                        type="text"
                        id="agentID"
                        value={currentConfig.agentID}
                        onChange={handleInputChange}
                        className="w-full p-2 border border-gray-300 rounded-lg"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="theirName">
                        Their Name
                    </label>
                    <input
                        type="text"
                        id="theirName"
                        value={currentConfig.theirName}
                        onChange={handleInputChange}
                        className="w-full p-2 border border-gray-300 rounded-lg"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">Tips</label>
                    {currentConfig.tips.map((tip, index) => (
                        <div key={index} className="flex items-center mb-2">
                            <input
                                type="text"
                                value={tip}
                                onChange={(e) => handleTipsChange(index, e.target.value)}
                                className="w-full p-2 border border-gray-300 rounded-lg mr-2"
                                placeholder={`Tip ${index + 1}`}
                            />
                            <button
                                type="button"
                                onClick={() => handleRemoveTip(index)}
                                className="text-red-500 hover:text-red-700"
                            >
                                Remove
                            </button>
                        </div>
                    ))}
                    <button
                        type="button"
                        onClick={handleAddTip}
                        className="text-blue-500 hover:text-blue-700"
                    >
                        Add Tip
                    </button>
                </div>

                <div>
                    <button
                        type="submit"
                        className="w-full py-2 px-4 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600"
                    >
                        {currentConfig.id ? 'Update Config' : 'Save Config'}
                    </button>
                </div>
            </form>

            <div className="mt-8">
                <h2 className="text-xl font-semibold mb-4">Existing Configurations</h2>
                <ul>
                    {configs.map((config) => (
                        <li key={config.id} className="flex justify-between items-center mb-4 p-4 border border-gray-300 rounded-lg">
                            <div>
                                <h3 className="text-lg font-semibold">{config.company_name}</h3>
                                <p className="text-gray-600">Agent ID: {config.agent_id}</p>
                                <p className="text-gray-600">Name: {config.name}</p>
                                <p className="text-gray-600">Tips: {config.tips.join(', ')}</p>
                            </div>
                            <div className="flex space-x-2">
                                <button
                                    onClick={() => handleEdit(config)}
                                    className="text-blue-500 hover:text-blue-700"
                                >
                                    Edit
                                </button>
                                <button
                                    onClick={() => handleDelete(config.id)}
                                    className="text-red-500 hover:text-red-700"
                                >
                                    Delete
                                </button>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default ConfigForm;
